import React from 'react';
import { PageProps } from "gatsby";
import Layout from '../../components/layout/layout';

const previewImage = {imageFile: 'home.png', imageWidth: 1200, imageHeight: 630, imageDescription: 'Screenshot of the website'};

export default function PageContent(props: PageProps) {
  let language = 'en';
  return (
    <Layout language={language} title="About me" description="Some more things about myself" path={props.path} previewimage={previewImage} hasLanguageCounterpart={false}>

Examples for Code

    </Layout>
  );
}

